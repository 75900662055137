import { forwardRef, MutableRefObject, useState } from 'react';
import {
  CountrySelectorDropdown,
  defaultCountries,
  FlagImage,
  usePhoneInput,
} from 'react-international-phone-field';
import { ParsedCountry } from 'react-international-phone-field/build/types';

import { Button } from '../button';
import { ExpandLess } from '../icons/generated/expand-less';
import { ExpandMore } from '../icons/generated/expand-more';
import { CoreInput } from './CoreInput';
import { CoreInputWithLabelProps } from './CoreInputWithLabel';
import { CoreLabel } from './CoreLabel';

// this component combines regular label and externa input for tel input

export type TelInputChangeEvent = {
  phone?: string;
  inputValue?: string;
  country?: ParsedCountry;
};

export type TelInputProps = Omit<CoreInputWithLabelProps, 'onChange'> & {
  type: 'tel';
  onChange?: (event: TelInputChangeEvent) => void;
};

export const TelInput = forwardRef<HTMLInputElement, TelInputProps>(
  ({ id, type, label, ...props }, ref) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // we use advanced API https://react-international-phone.vercel.app/docs/Advanced%20Usage/usePhoneInput
    // to ensure design aligment
    const {
      inputValue,
      handlePhoneValueChange,
      inputRef,
      country,
      setCountry,
    } = usePhoneInput({
      value: props.value ? String(props.value) : '',
      inputRef: ref as MutableRefObject<HTMLInputElement>,
      defaultCountry: 'de',
      countries: defaultCountries,
      onChange: (res) => props.onChange?.(res),
    });

    return (
      <>
        {
          // we do small hack with negative margin to ensure that whole button area is clickable
          // and external padding ignored
        }
        <Button
          variant="ghost"
          className="relative -ml-4 mr-3 h-[54px] px-0 pl-4 focus-visible:ring-offset-0"
          onClick={() => setIsDropdownOpen(true)}
        >
          <FlagImage iso2={country.iso2} width={24} />
          {isDropdownOpen ? (
            <ExpandLess className="box-content inline-block h-6 w-6 fill-black px-1.5" />
          ) : (
            <ExpandMore className="box-content inline-block h-6 w-6 fill-black px-1.5" />
          )}
          <div className="border-pearl h-10 border-0 border-l border-solid" />
        </Button>
        <CountrySelectorDropdown
          className="shadow-highlightPewter z-overlay absolute left-0 top-14 m-0 mt-0.5 h-80 max-h-80 w-full overflow-auto bg-white p-0 outline-none"
          listItemClassName="flex items-center px-2 py-3 min-h-12 box-border hover:bg-snow cursor-pointer"
          listItemFlagClassName="mr-2 w-6 h-6"
          listItemCountryNameClassName="body-14-regular text-black"
          listItemDialCodeClassName="body-14-regular ml-2 text-iron"
          show={isDropdownOpen}
          selectedCountry={country.iso2}
          onSelect={(data) => setCountry(data.iso2)}
          onClose={() => setIsDropdownOpen(false)}
        />
        <div className="relative w-full">
          <CoreInput
            id={id}
            type={type}
            ref={inputRef}
            label={label}
            {...props}
            value={inputValue}
            onChange={handlePhoneValueChange}
          />
          {label ? (
            <CoreLabel id={id} type={type} shouldShowLabelAtTop>
              {label}
            </CoreLabel>
          ) : null}
        </div>
      </>
    );
  }
);
